import {mapActions, mapGetters} from "vuex";

export default {
    data() {
        return {
            checkedStatus: null,
        }
    },
    computed: {
        ...mapGetters({
            orderStatusList: 'orders/orderStatusList',
        })
    },
    created() {
        this.getOrderStatuses();
    },
    methods: {
        closePopup() {
            this.$emit('closePopup');
        },
        changeStatus() {
            this.$emit('changeStatus', this.checkedStatus);
            this.$emit('closePopup');
        },
        ...mapActions({
            getOrderStatuses: 'orders/getOrderStatuses',
        })
    }
}